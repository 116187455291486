import store from "@store";
import { logout, updateToken } from "@store/auth/auth.actions";
import { appInfoHeaders, refreshJwtToken } from "@store/auth/auth.service";
import { WFAxios } from "./api.instance";

const renewToken = async (retryCount = 0) => {
  if (retryCount >= 7) {
    throw new Error("Giving up Retry!");
  }

  // Simulate a delay of 5 seconds
  if (retryCount !== 0)
    await new Promise((resolve) => setTimeout(resolve, 5000));

  try {
    const {
      auth: { orgToken, refreshToken },
    } = store.getState();

    // Make the renewToken request
    const { data } = await refreshJwtToken({ refreshToken, orgId: orgToken });

    return data;
  } catch (error) {
    console.error(`Retry ${retryCount + 1} failed. Retrying...`);
    return renewToken(retryCount + 1);
  }
};

export const requestSuccess = (config) => {
  const {
    auth: { authToken, orgToken },
  } = store.getState();

  config.headers = {
    ...config.headers,
    ...appInfoHeaders,
  };

  if (authToken) config.headers.Authorization = `Bearer ${authToken}`;
  if (orgToken) config.headers.organizationId = orgToken;

  if (config.headers.Authorization && authToken) return config;
  // eslint-disable-next-line no-throw-literal
  throw { message: "Invalid token" };
};

export const requestFailure = async (error) => {
  const originalRequest = error.config;

  if (
    (error.response?.status === 401 || error.response?.status === 403) &&
    !originalRequest._retry
  ) {
    originalRequest._retry = true;

    try {
      // Renew token
      const data = await renewToken();
      store.dispatch(
        updateToken({
          authToken: data.jwt_token,
          refreshToken: data?.refresh_token,
        })
      );
      // Update the original request with the new token
      originalRequest.headers["Authorization"] = `Bearer ${data.jwt_token}`;

      // Retry the original request
      return WFAxios(originalRequest);
    } catch (renewError) {
      // Handle token renewal failure
      console.error("Token renewal failed:", renewError);

      // Redirect to login or handle the error accordingly
      // For example:
      // history.push('/login');
      store.dispatch(logout({ timedOut: false }));

      return Promise.reject(renewError);
    }
  } else {
    throw error;
  }
};

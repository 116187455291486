export type ProjectStatus =
  | "Enquiry"
  | "Upcoming"
  | "InProgress"
  | "Completed"
  | "Closed"
  | "Cancelled";

export type Project = {
  beginDate?: number;
  beginDateStr?: string;
  complDate?: number;
  complDateStr?: string;
  actualStartDt?: number;
  actualStartDtStr?: string;
  assignedUntilDt?: number;
  assignedUntilDtStr?: string;
  alertFlg?: boolean;
  alertMsg?: string;
  orgzAlias?: string;
  orgzId?: string;
  customerAlias?: string;
  customerCode?: string;
  customerId?: string;
  customerName?: string;
  createdDate?: number;
  name?: string;
  organizationId?: string;
  code?: string;
  projectId?: string;
  colorCode?: string;
  bgColor?: string;
  billCycle?: string;
  borderColor?: string;
  remarks?: string;
  reqStatus?: string;
  reqStatusMsg?: string;
  sites?: Site[];
  status?: { name: ProjectStatus; colorHex: string };
  createdBy?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
};

export type Shift = {
  shiftId: string;
  name: string;
  code: string;
  fromTimeStr: string;
  toTimeStr: string;
  colorCode: string;
};

export type Site = {
  siteId: string;
  name: string;
  code: string;
  beginDateStr: string;
  complDateStr: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  createdBy: string;
  createdDate: string;
  status?: { name: ProjectStatus; colorHex: string };
  shift: {
    shiftId: string;
    name: string;
    code: string;
    fromTimeStr: string;
    toTimeStr: string;
    colorCode: string;
  };
};

export type ProjectFilter = {
  status: string;
  color: string;
  count: number;
};

export class ProjectQuery {
  filter?: string = "";
  pageNumber?: number = 1;
  pageSize?: number = 20;
  sortOrder?: "desc" | "asc" = "asc";
  sortField?: string = "pj.name";
  status?: string[] = ["InProgress"];
  orgId?: string[] = [];
}

export type TimeRule = {
  name: string;
  projId: string;
  siteId: string;
  dayRuleId: string;
  hourRuleId: string;
  validFromStr: string;
  validToStr: string;
  remarks: string;
};

import { removeEmptyProps } from "@biznessforce/ui-lib";
import { WFAxios } from "@api/api.instance";
import axios from "axios";

const ACCOUNT_BASE_URL = `${import.meta.env.VITE_BASE_URL_ACCOUNT}/api`;

const ACCOUNT_SINGULAR_URL = `${ACCOUNT_BASE_URL}/account`;
const ACCOUNTS_PLURAL_URL = `${ACCOUNT_BASE_URL}/accounts`;
const ASSIGN_ROLE = `${ACCOUNT_BASE_URL}/account/assignrole`;

const constructParam = (payload) => ({
  searchTerm: payload.filter ? payload.filter.toLowerCase() : null,
  sort:
    payload.sortField.id !== "none"
      ? `${payload.sortField.id},${payload.sortOrder.toUpperCase()}`
      : null,
  page: payload.pageNumber.toString(),
  size: payload.pageSize.toString(),
  status: payload.status || null,
  roleId: payload.roleId || null,
});

// Organisation Service
export const getOrganisationDetail = (hostname) =>
  axios.get(`${ACCOUNT_BASE_URL}/hr/orginfo?host=${hostname}`);

// Accounts Service
export const createAccount = (account) =>
  WFAxios.post(ACCOUNT_SINGULAR_URL, account);

export const getAccountById = (accountId) =>
  WFAxios.get(`${ACCOUNT_SINGULAR_URL}/${accountId}`);

export const findAccounts = (query) => {
  const params = removeEmptyProps(constructParam(query));

  return WFAxios.get(ACCOUNTS_PLURAL_URL, { params });
};

export const updateAccount = (account, accountId) =>
  WFAxios.put(`${ACCOUNT_SINGULAR_URL}/${accountId}`, account);

export const disableAccount = (accountId) =>
  WFAxios.put(`${ACCOUNT_SINGULAR_URL}/${accountId}/disable`);

export const enableAccount = (accountId) =>
  WFAxios.put(`${ACCOUNT_SINGULAR_URL}/${accountId}/activate`);

export const deleteAccount = (accountId) =>
  WFAxios.delete(`${ACCOUNT_SINGULAR_URL}/${accountId}`);

export const changePassword = (accId, password, newPassword) =>
  WFAxios.put(`${ACCOUNT_SINGULAR_URL}/${accId}/changepassword`, {
    newPassword,
    password,
  });

export const resetPassword = (accId, newPassword) =>
  WFAxios.put(`${ACCOUNT_SINGULAR_URL}/${accId}/resetpassword`, {
    newPassword,
  });

export const assignRoles = (accountId, roleId, toRemove) => {
  const payload = { accountId, roleId };
  if (toRemove) payload["remove"] = true;
  return WFAxios.post(ASSIGN_ROLE, payload, {
    headers: { forAccId: accountId },
  });
};

export const getAllRoles = (forAccId) => {
  const params = {};
  if (forAccId) params["forAccId"] = forAccId;
  return WFAxios.get(`${ACCOUNT_BASE_URL}/roles`, { params });
};

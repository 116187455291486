import { WFAxios } from "@api/api.instance";
import { removeEmptyProps } from "@biznessforce/ui-lib";
import { EmployeeQuery } from "./Employee.model";
import { AxiosResponse, CancelToken } from "axios";
import { EmpLeave } from "../overview/Overview.model";

const constructParam = (payload: EmployeeQuery) => ({
  searchTerm: payload.filter ? payload.filter.toLowerCase() : null,
  sort:
    payload?.sortField && payload?.sortField !== "none"
      ? `${payload?.sortField},${payload?.sortOrder?.toUpperCase()}`
      : null,
  page: payload?.pageNumber?.toString(),
  size: payload?.pageSize?.toString(),
  skillId:
    payload?.skillIds && payload?.skillIds?.length
      ? payload?.skillIds.map((skill) => skill).join(",")
      : null,
  status: payload?.status || "",
  resigned: payload?.resigned,
  hasAcc: payload?.hasAcc,
  partnerId:
    payload?.partnerId && payload?.partnerId?.length
      ? payload?.partnerId?.map((partner) => partner).join(",")
      : null,
  orgzId: payload?.orgId ? payload?.orgId : null,
  projId: payload?.projId,
  inclSkill: true,
  inclPhoto: true,
});

const WORKFORCE_HR_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/hr/api`;

export const createEmployee = (employee: any) =>
  WFAxios.post(`${WORKFORCE_HR_BASE_URL}/employee`, employee);

export const updateEmployee = (employee: any) =>
  WFAxios.put(`${WORKFORCE_HR_BASE_URL}/employee`, employee);

export const uploadEmployees = (file: File): Promise<AxiosResponse> => {
  const finalFile = new FormData();
  finalFile.append("file", file);
  return WFAxios.post(
    `${import.meta.env.VITE_BASE_URL_WF}/proj/api/employees/upload`,
    finalFile,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const uploadEmployeePhoto = (
  employeeId: string,
  file: any
): Promise<AxiosResponse> => {
  const finalFile = new FormData();
  finalFile.append("file", file);

  return WFAxios.post(
    `${WORKFORCE_HR_BASE_URL}/employee/${employeeId}/photo/upload`,
    finalFile,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

// Create Account for Employee
export const createEmpAccount = (
  empId: string,
  payload: { login: string; password: string; roleIds: string[] }
) =>
  WFAxios.post(`${WORKFORCE_HR_BASE_URL}/employee/${empId}/account`, payload);

export const getEmplLoginAccount = (empId: string) =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/employee/${empId}/account`);

export const getEmployeeById = (employeeId: string) =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/employee/${employeeId}?inclPPUrl=true`);

export const findEmployees = (query: EmployeeQuery) => {
  const params: any = removeEmptyProps(constructParam(query));
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/v2/employees`, { params });
};

export const findEmployeesByProject = ({
  projId,
  searchTerm,
  fromDt,
  toDt,
}: {
  projId: string;
  searchTerm?: string;
  fromDt: string;
  toDt?: string;
}) => {
  const params: any = removeEmptyProps({ projId, searchTerm, fromDt, toDt });
  return WFAxios.get(`${import.meta.env.VITE_BASE_URL_WF}/proj/api/employees`, {
    params,
  });
};

export const getAllSkills = (searchTerm?: string, cancelToken?: CancelToken) =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/skills`, {
    params: { searchTerm },
    cancelToken,
  });

export const getEmpSkills = (employee: any) =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/employee/${employee}/skills`);

export const addSkill = (skill: any) =>
  WFAxios.post(`${WORKFORCE_HR_BASE_URL}/employee/skill`, skill);

export const updateSkill = (skill: any) =>
  WFAxios.put(`${WORKFORCE_HR_BASE_URL}/employee/skill`, skill);

export const deleteSkill = (empId: any, skillId: any) =>
  WFAxios.delete(`${WORKFORCE_HR_BASE_URL}/employee/skill`, {
    params: { empId, skillId },
  });

export const getLeaveDetail = (
  leaveId: string
): Promise<AxiosResponse<EmpLeave>> =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/emp/leave/${leaveId}`);

export const applyLeave = (payload: any) =>
  WFAxios.post(`${WORKFORCE_HR_BASE_URL}/emp/leave`, payload);

export const updateLeave = (payload: any) =>
  WFAxios.put(`${WORKFORCE_HR_BASE_URL}/emp/leave`, payload);

export const removeEmpPhoto = (empId: string) =>
  WFAxios.delete(`${WORKFORCE_HR_BASE_URL}/employee/${empId}/photo`);

export const deleteLeave = (leaveId: string, rosterDayId: string) =>
  WFAxios.delete(`${WORKFORCE_HR_BASE_URL}/emp/leave/${leaveId}`, {
    params: { rosterDayId: rosterDayId || null },
  });

export const addEmpDoc = (doc: any) =>
  WFAxios.post(`${WORKFORCE_HR_BASE_URL}/employee/doc`, doc);

export const getEmpDocs = (empId: any) =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/employee/${empId}/docs`);

export const getDocDetail = (docId: any) =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/employee/doc/${docId}`);

export const deleteEmpDoc = (docId: any) =>
  WFAxios.delete(`${WORKFORCE_HR_BASE_URL}/employee/doc/${docId}`);

export const updateEmpDoc = (payload: any) =>
  WFAxios.put(`${WORKFORCE_HR_BASE_URL}/employee/doc`, payload);

export const getResourcePartners = () => {
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/resrpartners`);
};

export const getAllOrgz = () => {
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/orgzs`);
};

export const markEmployeeAsResigned = (payload: {
  employeeId: string;
  endStr: string;
  remarks: string;
}) => WFAxios.put(`${WORKFORCE_HR_BASE_URL}/employee/resigned`, payload);

const WORKFORCE_PROJ_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/proj/api`;
export const getRecentProject = (empId: string, searchYear: string) =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/emp/${empId}/recentproj`, {
    params: { searchYear },
  });
